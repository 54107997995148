import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import CouponModal from "./CouponModal";
import CouponSuccessModal from "./CouponSuccessModal";
import CloseStoreModal from "./CloseStoreModal";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class PaymentButtons extends React.Component {
    constructor(props) {
        super(props)
    
        // this.hasCoupon = this.props.myShopApiStore.getCoupon()
        this.state = {
             isCouponModalOpen: false,
             closeStoreModalIsOpen: false,
             successModalIsOpen: false, //coupon success activation
        }
    }

    openSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: true})
    }

    closeSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: false})
    }

    openCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: true})
    }

    closeCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: false})
    }

    openCouponModal = () => {
        this.setState({isCouponModalOpen: true})
    }

    closeCouponModal = (isSuccess) => {
        this.setState({isCouponModalOpen: false})

        if(isSuccess){
            this.openSuccessModal();
        }
    }

    openPaymentPage = () => {
        const { setActiveTopMenu } = this.props.ifaceMngr;
        setActiveTopMenu("Payment")
    }

    handleGoToPaymentClick = () => {
        const { resetOrderData, shop, setSearchValue } = this.props.myShopApiStore;
        const { orderTypes } = shop;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;

        setSearchValue('', true);

        if(!hasDeliveryOp && !hasPickupOp){
            this.openCloseStoreModal();
            return;
        }
        resetOrderData(); //clean order data details
        this.openPaymentPage();
    }
    
    render() {
        const { getCoupon, mainColor, shop } = this.props.myShopApiStore;
        const hasCoupon = getCoupon();
        return (
            <div className={style.Container}>
                <CouponSuccessModal closeModal={this.closeSuccessModal} modalIsOpen={this.state.successModalIsOpen}/>
                <CloseStoreModal closeModal={this.closeCloseStoreModal} modalIsOpen={this.state.closeStoreModalIsOpen}/>
                { this.state.isCouponModalOpen && <CouponModal closeModal={this.closeCouponModal}/> }
                <div className={style.PaymentBtns}>
                {
                    shop.isBusiness
                    ?
                        <div style={{height: "25px", cursor: 'unset'}}/>
                    :
                        <div onClick={(this.state.isCouponModalOpen || hasCoupon) ? null : this.openCouponModal} disabled={this.state.isCouponModalOpen || hasCoupon} style={{backgroundColor: hasCoupon && "rgb(217, 217, 217)", border: `1px solid ${mainColor}`, color: mainColor}}>יש לך קוד קופון?&nbsp;<span>לחץ כאן</span></div>
                }
                   <div onClick={this.handleGoToPaymentClick} disabled={this.state.isCouponModalOpen} style={{ backgroundColor: mainColor }}>מעבר לתשלום</div>
                   <div disabled={this.state.isCouponModalOpen} style={{ margin: 0}}>דמי משלוח יתעדכנו לאחר הזנת הכתובת</div>
                </div>
            </div>
        )
    }
}

export default PaymentButtons
